export const GET_ORDERS = "GET_ORDERS";
export const LIST_ORDERS = "LIST_ORDERS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const LIST_ORDER = "LIST_ORDER";
export const ORDER_ERROR = "ORDER_ERROR";
export const LOAD_MORE = "LOAD_MORE";
export const FETCH_MORE = "FETCH_MORE";
export const SYNC_ORDER = "SYNC_ORDER";
export const ORDER_STATUS_UPDATE = "ORDER_STATUS_UPDATE";
export const UPDATE = "UPDATE";
export const UPDATE_ERROR = "UPDATE_ERROR";
export const SYNC_STATUS = "SYNC_STATUS";
export const GET_STATUS = "GET_STATUS";
