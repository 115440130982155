// @flow

import { styled } from "baseui";

export const StyledContent = styled<{}>("div", ({ $theme }) => {
  const { breakpoints } = $theme;
  return {
    width: "100%",
    [`@media (min-width: ${breakpoints.medium}px)`]: {
      maxWidth: "516px",
    },
  };
});

export const StyledContentBlock = styled<{}>("div", ({ $theme }) => {
  const { breakpoints } = $theme;
  return {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Regular",
    [`@media (min-width: ${breakpoints.medium}px)`]: {
      padding: "32px",
    },
    [`@media (max-width: ${breakpoints.medium - 1}px)`]: {
      padding: "32px 16px",
    },
  };
});

export const StyledHeading = styled<{}>("div", ({ $theme, smallTrue }) => {
  const { typography } = $theme;
  return {
    ...typography.HeadingLarge,
    marginBottom: "24px",
    textAlign: "center",
    fontSize: smallTrue ? "20px" : "24px"
  };
});

export const StyledAlertBlock = styled<{}>("div", ({ $theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: "#ab1300",
  };
});

export const StyledActionBlock = styled<{}>("div", ({ $theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "24px",
  };
});

export const StyledLeftBlock = styled<{}>("div", ({ $theme }) => {
  return {
    paddingRight: "8px",
  };
});

export const StyledRightBlock = styled<{}>("div", ({ $theme }) => {
  return {
    paddingLeft: "8px",
  };
});
