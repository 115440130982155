// @flow

import { combineReducers } from "redux";
import ForgotPassReducer from "./forgot-password/reducer";
import OrderReducer from "./order/reducer";
import ProfileReducer from "./verify-phone/reducer";
import TrackingReducer from "./order-tracking/reducer";

const rootReducer = combineReducers({
  forgot_password: ForgotPassReducer,
  profile: ProfileReducer,
  order: OrderReducer,
  tracking: TrackingReducer,
  user: OrderReducer
});

export default rootReducer;
