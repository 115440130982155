export const baseURL =
  process.env.REACT_APP_BACKEND_URL || "https://dev.core.quatrixglobal.com/";
export const googleApIKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
export const flutterwavePublicKey =
  process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY || "";
export const pontyMobileTraccar = process.env.REACT_APP_MOBILE_TRACCAR_URL;
export const quatrixMobileTraccar = process.env.REACT_APP_MOBILE_TRACCAR_URL;
export const pontyTraccarUrl = process.env.REACT_APP_TRACCAR_URL;
export const quatrixTraccarUrl = process.env.REACT_APP_TRACCAR_URL;
export const trackUrl = process.env.REACT_APP_TRACK_URL;
export const traccarURL = process.env.REACT_APP_TRACCAR_URL;
export const mobileTraccarURL = process.env.REACT_APP_MOBILE_TRACCAR_URL;
export const traccarToken = process.env.REACT_APP_TRACCAR_TOKEN;
