import React from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = React.createContext({});

const AuthProvider = (props) => {
  const isValidToken = () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // JWT decode & check token validity & expiration.
      const decoded = jwt_decode(token);
      //check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp > currentTime) return true;
      return false;
    }
    return false;
  };

  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const [isVerified, makeVerified] = React.useState(false);
  const [account, setAccount] = React.useState(null);

  function authenticate({ token, verified, account }, cb) {
    makeAuthenticated(true);
    makeVerified(() => verified);
    setAccount(() => account);
    localStorage.setItem("jwtToken", token);
    cb();
    // setTimeout(cb, 1000); // fake async
  }

  function verify(verified, cb) {
    makeVerified(() => verified);
    setTimeout(cb, 100); // fake async
  }

  function profile(user, cb) {
    setAccount(() => user);
    setTimeout(cb, 100); // fake async
  }

  function signout(cb) {
    makeAuthenticated(false);
    makeVerified(false);
    setAccount(null);
    localStorage.removeItem("jwtToken");
    setTimeout(cb, 100); // fake async
  }

  return (
    <AuthContext.Provider
      value={{
        isVerified,
        isAuthenticated,
        account,
        authenticate,
        verify,
        profile,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
